function clampString(inputString, maxLength) {
    if(inputString.length <= maxLength) {
        return inputString;
    }
    // Если первое условие ( длина строки меньше максимальной ) сработало, то следующий код не выполнится
 
    inputString = inputString.slice(0, maxLength);
    inputString += "...";
 
    return inputString;
}