$(document).ready(function () {
    // $('article.people-post .people-acc .body').hide();
    $('article.people-post .people-acc .head').click(function () {
        if($(this).hasClass('active')) {
            $(this).removeClass("active").next(".body").slideUp(600);
            $(this).find('span').removeClass('opened');
        } else {
            $(this).next('.body').slideDown(600);
            $(this).removeClass('active');
            $(this).addClass("active").next(".body").slideDown(600);
            $(this).find('span').addClass('opened');
        }
        return false;
    })
});