$(document).on('click', '.arrows div', function() {
    var elem = $(this),
        index = elem.index(),
        parent = elem.parent(),
        slider = $('.' + parent.data('slider'));

    if(index){
        slider.slick('slickNext');
    }else{
        slider.slick('slickPrev');
    }
});

$('.slider-arrows .arrow').click(function(){
    var elem    = $(this),
        dir     = elem.data('direction') == 'prev' ? 'Prev' : 'Next',
        parent  = elem.parents('.section-title'),
        slider  = parent.siblings('.slick-slider');
  
      if(slider.length == 0){
        slider = parent.siblings().find('.slick-slider');
      }
  
    slider.slick('slick' + dir);
  })