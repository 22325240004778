$('.projects-slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: $('.projects-arrows .icon-left-arrow'),
    nextArrow: $('.projects-arrows .icon-right-arrow'),
    responsive: [
      {
        breakpoint: 1199.98,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                  }
      },
      {
        breakpoint: 991.98,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
      },
      {
        breakpoint: 767.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.people-slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: $('.people-arrows .icon-left-arrow'),
    nextArrow: $('.people-arrows .icon-right-arrow'),
    responsive: [
                {
                  breakpoint: 1199.98,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                  }
                },
                {
                  breakpoint: 991.98,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 767.98,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 575.98,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
               
            ]
  });

